'use client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { loginViaEmail } from '../utils/loginViaEmail';
import { getCurrentUserInfoKey } from './useCurrentUserInfo';
import { getCurrentSessionKey } from './useCurrentUserSession';

const sanitizeEmail = (email?: string) => (email ? email.trim().toLowerCase() : '');

export const useLoginMutation = () => {
  const queryClient = useQueryClient();

  const loginMutation = useMutation({
    mutationFn: (variables: { username: string; password: string }) =>
      loginViaEmail({ ...variables, username: sanitizeEmail(variables.username) }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getCurrentUserInfoKey(),
      });

      queryClient.invalidateQueries({
        queryKey: getCurrentSessionKey(),
      });
    },
  });

  return { loginMutation };
};
