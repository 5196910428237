import type { AnalyticsBrowser } from '@segment/analytics-next';
import type { MutableRefObject } from 'react';
import { createContext } from 'react';

export type AnalyticsContextValues = {
  analyticsRef: MutableRefObject<AnalyticsBrowser | undefined>;
  globalPropsRef: MutableRefObject<{}>;
};

const defaultAnalyticsContext: AnalyticsContextValues = {
  analyticsRef: {
    current: undefined,
  },
  globalPropsRef: {
    current: {},
  },
};

export const AnalyticsContext = createContext<AnalyticsContextValues>(defaultAnalyticsContext);
