import { Caution } from '@air/next-icons';
import { AlertBanner } from '@air/primitive-alert-banner';
import { detect } from 'detect-browser';
import { memo, useState } from 'react';
import { useMount } from 'react-use';

type BrowserType = 'chrome' | 'firefox' | 'safari' | 'edge' | 'opera';

interface SupportedBrowser {
  minVersion: number;
  updateLink: string;
  type: BrowserType;
}

const supportedBrowsers: { [key in BrowserType]: SupportedBrowser } = {
  chrome: {
    type: 'chrome',
    minVersion: 95,
    updateLink: 'https://www.google.com/chrome/',
  },
  firefox: {
    type: 'firefox',
    minVersion: 90,
    updateLink: 'https://www.mozilla.org/firefox/new/',
  },
  safari: {
    type: 'safari',
    minVersion: 15,
    updateLink: 'https://support.apple.com/HT204416',
  },
  opera: {
    type: 'opera',
    minVersion: 78,
    updateLink: 'https://www.opera.com/download',
  },
  edge: {
    type: 'edge',
    minVersion: 92,
    updateLink: 'https://www.microsoft.com/en-us/edge',
  },
};

const getOutdatedBrowser = (browser: ReturnType<typeof detect>): SupportedBrowser | null => {
  if (!browser || !browser.version) {
    return null;
  }
  try {
    const majorVersion = parseInt(browser.version.split('.')[0], 10);
    switch (browser.name) {
      case 'edge':
      case 'chrome':
      case 'firefox':
      case 'safari':
      case 'opera':
        if (supportedBrowsers[browser.name].minVersion >= majorVersion) {
          return supportedBrowsers[browser.name];
        }
        break;
    }
  } catch (error) {
    // no need to do anything
  }
  return null;
};

export const OutdatedBrowserBanner = memo(() => {
  const [outdatedBrowser, setOutdatedBrowser] = useState<SupportedBrowser | null>(null);

  useMount(() => {
    const browser = detect();
    setOutdatedBrowser(getOutdatedBrowser(browser));
  });

  if (!outdatedBrowser) {
    return null;
  }

  return (
    <AlertBanner color="red" className="sticky top-0 z-[10000]" prefix={<Caution className="h-4 w-4" />}>
      Your browser is out of date. Please update your browser to the latest version by Mar 10, 2023 to receive the
      newest features and security fixes on Air”.&nbsp;
      <button className="font-semibold" onClick={() => window.open(outdatedBrowser?.updateLink, '_blank')}>
        Learn more
      </button>
    </AlertBanner>
  );
});

OutdatedBrowserBanner.displayName = 'OutdatedBrowserBanner';
