import { Account } from '@air/api';
import { Airror, matchesAirror, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED } from '@air/errors';

export const getIsSAMLEnforced = async (email: string) => {
  try {
    const ssoOptions = await Account.getAccountSSOOptionsV2({ email });

    if (ssoOptions instanceof Airror) {
      throw ssoOptions;
    }

    return ssoOptions.data.length > 0
      ? !!ssoOptions.data.find((ssoOption) => !!ssoOption.enforce && !!ssoOption.isMember)
      : false;
  } catch (error) {
    if (matchesAirror(error, WORKSPACE_INTEGRATION_SSO_NOT_ENABLED)) {
      return false;
    } else {
      throw error;
    }
  }
};
