import { ReloadApplicationPrompt } from '~/components/ReloadApplication/components/ReloadApplicationPrompt';
import { useReloadApplication } from '~/providers/ReloadApplicationProvider';

export type ReloadApplicationProps = {};

export const ReloadApplication = () => {
  const { hasNewRelease, pendingActions, onReload } = useReloadApplication();

  if (hasNewRelease && !pendingActions.length) {
    return <ReloadApplicationPrompt onReload={onReload} />;
  }

  return null;
};
