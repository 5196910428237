import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import classNames from 'classnames';
import { isFunction, isNumber } from 'lodash';
import { type ReactNode, useEffect, useState } from 'react';

export type ToastColor = 'black' | 'red';

const TOAST_COLOR_CLASS_NAMES: { [key in ToastColor]: string } = {
  black: 'bg-pigeon-700 text-white',
  red: 'bg-flamingo-600 text-white',
};

export interface ToastItemProps {
  children: ((dismiss: () => void) => ReactNode) | ReactNode;
  color?: ToastColor;
  onDismiss: () => void;

  /** This is a component that can show in front of the message.. */
  prefix?: ReactNode;
  /** This is a component that can show in back of the message.. */
  suffix?: ReactNode;
  timeBeforeAutoDismiss?: number | null;
  /**
   * Controls whether the assistive technology should read immediately ("assertive") or wait until the
   * user is idle ("polite"). Think to yourself: "If this toast wasn't visible, would a user be confused?"
   * If the answer to that question is "yes", the type should probably be "assertive".
   *
   * "assertive" makes the toast have `role="alert"` and "polite" makes the toast have `role="status"`
   *
   * @default 'polite'
   * @example Success notifications can be polite, and server errors from a form submission should be alert.
   * @see https://reach.tech/alert#alert-type
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_status_role
   */
  type?: 'assertive' | 'polite';
  withCloseButton?: boolean;
}

export const ToastItem = ({
  children,
  color = 'black',
  onDismiss,
  prefix,
  suffix,
  timeBeforeAutoDismiss,
  type,
  withCloseButton,
}: ToastItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timer = 0;

    if (isNumber(timeBeforeAutoDismiss) && !isHovered) {
      timer = window.setTimeout(onDismiss, timeBeforeAutoDismiss);
    }

    return () => clearTimeout(timer);
    // dismiss as dependency causes issue with resetting the timeout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, timeBeforeAutoDismiss]);

  return (
    <div
      className={classNames(
        'relative my-1 flex min-h-[48px] items-center gap-3 overflow-hidden rounded-lg px-4 py-3 shadow-popover',
        TOAST_COLOR_CLASS_NAMES[color],
      )}
      data-testid="TOAST_ITEM"
      role={type === 'assertive' ? 'alert' : 'status'}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
    >
      {!!prefix ? (
        <div className="shrink-0" data-testid="TOAST_PREFIX">
          {prefix}
        </div>
      ) : null}

      <span className="text-14 font-medium" data-testid="TOAST_TEXT">
        {isFunction(children) ? children(onDismiss) : children}
      </span>

      {!!suffix ? (
        <div className="shrink-0" data-testid="TOAST_PREFIX">
          {suffix}
        </div>
      ) : null}

      {withCloseButton && (
        <IconButton
          appearance="ghost"
          color="grey"
          className="shrink-0 text-white/80"
          icon={Close}
          label="Close toast"
          onClick={onDismiss}
          size="small"
        />
      )}
    </div>
  );
};
