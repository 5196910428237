import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type SwitchedVersion = { location: 'dropdown' | 'tab' | 'mobile' | 'discussion' };

export const useTrackSwitchedVersion = () => {
  const { track } = useAnalyticsUtilities();

  const trackSwitchedVersion = useCallback(
    (params: SwitchedVersion) => {
      track(`Navigated between versions`, params);
    },
    [track],
  );

  return { trackSwitchedVersion };
};
