'use client';
import { Portal } from '@air/primitive-portal';
import { animated, config, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import { constant, noop, uniqueId } from 'lodash';
import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { ToastItem, ToastItemProps } from './ToastItem';

export interface ToastOptions extends Omit<ToastItemProps, 'children' | 'onDismiss'> {
  onDismiss?: () => void;
}

export interface Toast {
  id: string;
  content: ToastItemProps['children'];
  options?: ToastOptions;
}

const createToast = (content: Toast['content'], options?: ToastOptions): Toast => ({
  id: uniqueId('toast'),
  content,
  options,
});

const toastOptionDefaults: Required<ToastOptions> = {
  color: 'black',
  onDismiss: noop,
  prefix: null,
  suffix: null,
  timeBeforeAutoDismiss: 5_000,
  withCloseButton: false,
  type: 'polite',
};

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastContextValue {
  showToast: (content: Toast['content'], options?: ToastOptions) => void;
  dismissAllToasts: () => void;
  setToastBottomOffset: (offset: number) => void;
  setToastXOffset: (offset: number) => void;
  getToastXOffset: () => number;
}

const defaultValue: ToastContextValue = {
  showToast: noop,
  dismissAllToasts: noop,
  setToastBottomOffset: noop,
  setToastXOffset: noop,
  getToastXOffset: constant(0),
};

const ToastContext = createContext(defaultValue);

export const ToastProvider = memo(({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [bottomOffset, setToastBottomOffset] = useState<number>(0);
  const toastXOffsetRef = useRef<number>(0);
  const dismissAllToasts = useCallback(() => {
    // do not set an empty array if there are no toast to avoid unnecessary rerenders on route change
    setToasts((currentToasts) => (currentToasts.length === 0 ? currentToasts : []));
  }, []);

  const clearToast = (id: string) => setToasts((current) => current.filter((t) => t.id !== id));

  const value: ToastContextValue = useMemo(
    () => ({
      dismissAllToasts,
      showToast: (content, options) => {
        setToasts((currentToasts) => [...currentToasts, createToast(content, options)]);
      },
      setToastBottomOffset,
      setToastXOffset: (offset: number) => {
        toastXOffsetRef.current = offset;
      },
      getToastXOffset: () => toastXOffsetRef.current,
    }),
    [dismissAllToasts, toastXOffsetRef],
  );

  const transitions = useTransition(toasts, {
    keys: (item) => item.id,
    from: {
      opacity: 0,
      y: 16,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 16,
    },
    config: config.stiff,
  });

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Portal>
        <ul
          className={classNames(
            `pointer-events-none fixed bottom-0 right-0 z-[10] m-0 flex flex-col items-center overflow-hidden px-0 py-3`,
          )}
          style={{ width: `calc(100% - ${toastXOffsetRef.current}px)`, marginBottom: bottomOffset }}
        >
          {transitions((style, item) => {
            const { color, onDismiss, prefix, suffix, timeBeforeAutoDismiss, withCloseButton, type } = {
              ...toastOptionDefaults,
              ...item.options,
            };

            const dismissHandler = () => {
              onDismiss();
              clearToast(item.id);
            };

            return (
              <animated.div className="pointer-events-auto list-none" style={style}>
                <ToastItem
                  color={color}
                  onDismiss={dismissHandler}
                  prefix={prefix}
                  suffix={suffix}
                  type={type}
                  timeBeforeAutoDismiss={timeBeforeAutoDismiss}
                  withCloseButton={withCloseButton}
                >
                  {item.content}
                </ToastItem>
              </animated.div>
            );
          })}
        </ul>
      </Portal>
    </ToastContext.Provider>
  );
});

ToastProvider.displayName = 'ToastProvider';

export const useToasts = () => {
  const context = useContext(ToastContext);

  if (context === defaultValue) {
    throw 'useToasts used outside of ToastProvider';
  }

  return context;
};
